<template>
  <div class="user-home">
    <div class="user-left">
      <div class="user-info">
        <div class="user-img">
          <el-avatar :size="100" :src="userInfo.icon" :alt="userInfo.name"></el-avatar>
        </div>
        <div class="user-detail" >
          <div class="user-name">{{userInfo.name}}</div>
          <div class="user-transform">
            <div class="user-introduce">{{userInfo.introduce}}</div>
            <div v-if="isOwner()" class="user-btn">
              <el-button type="primary" @click="visiableUserEdit=true">编辑资料</el-button>
            </div>
          </div>
        </div>
      </div>
      <user-info
        v-if="visiableUserEdit"
        :userInfo="userInfo"
        @changeVisiable="visiableUserEdit=false"
        @getUserByUserName="getUserByUserName"
      ></user-info>
      <div v-else class="user-main">
        <div class="main-bar">
          <div class="user-tag" v-for="tag in tagList" :key="tag.id">
            <a href="#" @click="getTagArticle(tag.id)">{{tag.tagName}}</a>
          </div>
        </div>
        <div class="user-article">
          <el-card class="user-card">
            <div class="article-body" v-for="item in artilceList" :key="item.id">
              <div class="article-info">
                <div @click="toArticle(item.id)" class="article-left">
                  <el-image
                    class="article-img"
                    :alt="item.title"
                    :src="getBackImag(item.cover)"
                    fit="cover"
                    lazy
                  ></el-image>
                </div>
                <div class="article-right">
                  <div class="article-title">
                    <a @click="toArticle(item.id)">{{item.title}}</a>
                  </div>
                  <div class="article-digest">
                    <p>{{item.digest}}</p>
                  </div>
                  <div v-if="isOwner()" class="article-operator">
                    <el-tag v-if="item.status==2" type="warning">审核中</el-tag>
                    <router-link style="margin-left:5px;" :to="{name:'articleEdit',params: { articleId: item.id }}">
                      <el-tag effect="plain">修改</el-tag>
                    </router-link>
                    <a style="margin-left:5px;" href="#">
                      <el-tag type="danger" @click="removeArticle(item.id)" effect="plain">删除</el-tag>
                    </a>
                  </div>
                </div>
              </div>
              <div class="article-bottom">
                <i class="el-icon-date user-decorate">{{item.createTime}}</i>
                <i class="iconfont user-decorate">&#xe73c;{{item.approveCount}}</i>
                <i class="el-icon-chat-dot-round user-decorate">{{item.remarkCount}}</i>
              </div>
            </div>
            <div
              v-infinite-scroll="loadMore"
              infinite-scroll-disabled="busy"
              infinite-scroll-distance="10"
              class="scroll"
            >
              <el-avatar
                class="scroll-img"
                :size="50"
                :src="require('@/assets/img/profile.png')"
                alt="到底部了啊"
              ></el-avatar>
              <span class="scroll-text">没有更多了啊!</span>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <div class="user-right">
      <div class="user-ad"></div>
      <div class="other"></div>
    </div>
  </div>
</template>

<script>
import { decrypt } from "@/utils/encoder.js";
import { cover } from "@/utils/cover.js";
import userInfo from "@/components/userInfo/userInfo";
export default {
  // 路由参数隐藏
  props: ["username"],
  components: {
    userInfo
  },
  data() {
    return {
      userInfo: {},
      tagList: [],
      busy: false,
      queryInfo: {
        pageNum: 0,
        pageSize: 5
      },
      artilceList: [],
      decryptUser: decrypt(this.username),
      visiableUserEdit: false
    };
  },
  computed: {
    user() {
      return this.$store.state.permission.user;
    }
  },
  async mounted() {
    await this.getUserByUserName();
    this.getArticleTag();
  },
  methods: {
    async getUserByUserName() {
      const { data: res } = await this.$api.getUserByUserName(this.decryptUser);
      this.userInfo = res.data;
    }, // 标签列表
    async getArticleTag() {
      const { data: res } = await this.$api.getArticleTag({
        username: this.decryptUser
      });
      this.tagList = res.data;
    },
    getBackImag(url) {
      return cover(url);
    },
    isOwner() {
      if (this.decryptUser == this.user.username) {
        return true;
      } else {
        return false;
      }
    },
    // 跳转至文章详情页面
    toArticle(id) {
      this.$router.push({ name: "articleInfo", params: { articleId: id } });
    },
    // 删除文章
    async removeArticle(id) {
      const result = await this.$confirm("是否删除该文章?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$api.deleteArticleInfo(id);
      this.getTagArticle();
    },
    getTagArticle(value) {
      this.flag = true;
      if (value) {
        this.queryInfo.tagId = value;
      }
      this.queryInfo.pageNum = 0;
      this.artilceList = [];
      this.loadMore();
    },
    // 无限滚动
    loadMore: function() {
      this.busy = true;
      setTimeout(async () => {
        this.queryInfo.pageNum++;
        this.queryInfo.author = this.decryptUser;
        let param =  {}
        if(this.decryptUser == this.user.username){
          param = Object.assign({status:'2,4'},this.queryInfo)
        }else{
          param = Object.assign({status:'4'},this.queryInfo)
        }
        const { data: res } = await this.$api.getArticleInfo(param);
        if (res.data.size != 0) {
          this.artilceList.push.apply(this.artilceList, res.data.list);
          this.busy = false;
        } else {
          this.busy = true;
        }
      }, 500);
    }
  }
};
</script>

<style scoped>
@import url("../../assets/css/homepage.css");
</style>