<template>
  <div class="user-edit">
    <el-form label-position="right" label-width="80px" :model="userEdit">
      <el-form-item label="名称" prop="name">
        <el-input v-model="userEdit.name" placeholder="请输入昵称" maxlength="5" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="icon">
        <el-upload
          class="avatar-uploader"
          action
          :show-file-list="false"
          :http-request="uploadAvatar"
        >
          <img
            style="width:80px;height:80px;"
            v-if="userEdit.icon"
            :src="userEdit.icon"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
        </el-upload>
      </el-form-item>
       <el-form-item label="邮箱" prop="email">
        <el-input v-model="userEdit.email" placeholder="请输入邮箱" maxlength="25" show-word-limit ></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="email">
        <el-input v-model="userEdit.telephone" placeholder="请输入电话" maxlength="15" show-word-limit ></el-input>
      </el-form-item>
       <el-form-item label="地址" prop="address">
        <el-input v-model="userEdit.address" placeholder="请输入地址" maxlength="80" show-word-limit ></el-input>
      </el-form-item>
       <el-form-item label="简介" prop="introduce">
        <el-input type="textarea" v-model="userEdit.introduce" maxlength="50" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="注册时间" prop="createTime">
        <el-input v-model="userEdit.createTime" disabled></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" type="primary" @click="updateUser">保存</el-button>
      <el-button size="mini" type="primary" @click="cancleUpdateUser">返回</el-button>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object
    },
    visiableUserEdit: {
      type: Boolean
    }
  },
  data() {
    return {
      userEdit: {
        name: "",
        icon: "",
        introduce: "",
        email: "",
        address: "",
        telephone:'',
        createTime: ''
      }
    };
  },
  computed: {},
  mounted() {
    this.initUserInfo();
  },
  methods: {
    initUserInfo() {
      this.userEdit.name = this.userInfo.name;
      this.userEdit.icon = this.userInfo.icon;
      this.userEdit.introduce = this.userInfo.introduce;
      this.userEdit.telephone = this.userInfo.telephone;
      this.userEdit.email = this.userInfo.email;
      this.userEdit.address = this.userInfo.address;
      this.userEdit.createTime = this.userInfo.createTime;
    },
    async updateUser() {
      const { data: res } = await this.$api.editUserInfo(this.userEdit);
      await this.$emit("getUserByUserName");
      await this.$emit("changeVisiable");
    },
    cancleUpdateUser() {
      this.$emit("changeVisiable");
    },
    async uploadAvatar(content) {
      //验证图片格式大小信息
      const isLt1M = content.file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({ type: "error", message: "上传图片大小不能超过 1MB!" });
        return;
      }
      const formData = new FormData();
      formData.append("file", content.file);
      const { data: res } = await this.$api.uploadImg(formData);
      this.userEdit.icon = res.data;
      const { data: result } = await this.$api.editUserInfo(this.userEdit);
      await this.$emit("getUserByUserName");
    }
  }
};
</script>


<style lang="less" scoped>
.user-edit {
  margin: 20px 10px;
  .dialog-footer {
    padding-left: 80px;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #1846a3;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: #ffff;
}
</style>